@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

.oppo {
  background-image: url("https://i.postimg.cc/Kzh2J2dV/6939893.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  height: 100vh;
}
.input1, .textarea1, select {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  font-size: 0.8rem;
  border-radius: 4px; /* Optional: Add some border radius */
}

.input1:focus, .textarea1:focus, select:focus {
  outline: 1px solid #00b4cf;
}

.containercon {
  background-color: rgba(50, 47, 47, 0.9); /* Slight transparency */
  border-radius: 5px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 90%;
  max-width: 768px;
  min-height: 480px;
  margin: 0 10px;
  z-index: 2;
}

.nu {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.form-containercon {
  display: flex;
  flex-direction: column;
}

.left-containerop, .right-containerop {
  padding: 20px;
}

.left-containerop {
  background-color: rgba(26, 27, 28, 0.445);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-containerop {
  background-color: rgba(255, 255, 255, 0.7); /* Transparency for right container */
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-inner-container, .right-inner-container {
  text-align: center;
}

.input1, .textarea1 {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  font-size: 0.8rem;
}

.input1:focus, .textarea1:focus {
  outline: 1px solid #00b4cf;
}

.btnoy {
  border-radius: 20px;
  border: 1px solid rgb(33, 82, 115);
  background-color: rgb(33, 82, 115);
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}

.btnoy:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 600px) {
  .left-containerop {
    display: none;
  }

  .lg-view {
    display: none;
  }

  .form-containercon {
    flex-direction: column;
    width: 100%;
  }

  .right-containerop {
    width: 100%;
    padding: 20px;
  }
}

@media only screen and (min-width: 600px) {
  .form-containercon {
    flex-direction: row;
  }

  .sm-view {
    display: none;
  }

  .left-containerop, .right-containerop {
    flex: 1;
  }
}

form p {
  text-align: left;
}
