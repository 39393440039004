.card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .cardm {
    position: relative;
    width: calc(33.33% - 20px); /* Three cards in one row by default */
    margin: 10px;
    background-color: #f2f2f2;
    border-radius: 10px;
    overflow: hidden;
    perspective: 1000px;
    box-shadow: 0 0 0 5px #ffffff80;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .cardm img {
    width: 100%;
    height: 200px; /* Adjust height as needed */
    object-fit: cover;
  }
  
  .cardm:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
  }
  
  .card__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f2f2f2;
    transform: rotateX(-90deg);
    transform-origin: bottom;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .cardm:hover .card__content {
    transform: rotateX(0deg);
  }
  
  .card__title {
    margin: 0;
    font-size: 15px;
    color: #333;
    font-weight: 700;
  }

  .card__title1{
    margin: 0;
    font-size: 14px;
    color: #333;
    font-weight: 700;
  }
  
  .card__description {
    margin: 10px 0 0;
    font-size: 10px;
    color: #777;
    line-height: 1.4;
  }
  
  @media screen and (max-width: 768px) {
    .cardm {
      width: calc(50% - 20px); /* Two cards in one row on smaller screens */
    }
  }
  
  @media screen and (max-width: 480px) {
    .cardm {
      width: calc(100% - 20px); /* One card in one row on mobile screens */
      display: block;
    }
  }
  
 

  @media screen and (min-width: 600px) {
    .cardm {
        display: none;
    }
  }