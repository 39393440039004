.card3 {
    width: 100%;
    max-width: 350px; /* Increased width for large screens */
    min-width: 250px; /* Increased min width */
    height: 270px;
    background-color: #292929;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    border: 2px solid rgba(7, 7, 7, 0.12);
    font-size: 16px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    font-family: 'Rubik', sans-serif; 
    overflow: hidden; /* Ensure content stays within the card */
  }
  
  .icon {
    margin: 0 auto;
    width: 100%;
    height: 80px;
    max-width: 80px;
    background: linear-gradient(90deg, #7eaaff 0%, #ff48fb 40%, rgba(0, 0, 0, 0.28) 60%);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.8s ease;
    background-position: 0px;
    background-size: 200px;
  }
  
  .card3 .title {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 30px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
  }

  
  
  .card3 .text {
    width: 80%;
    margin: 0 auto;
    font-size: 13px;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-weight: 200;
    letter-spacing: 2px;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease;
  }
  
  .card3:hover {
    height: 350px; /* Increase height to fit the expanded text */
  }
  
  .card3:hover .text {
    opacity: 1;
    max-height: 100px; /* Increase max-height to accommodate text */
    transition: all 0.3s ease;
  }
  
  .card3:hover .icon {
    background-position: -120px;
    transition: all 0.3s ease;
  }
  
  .icon img {
    max-width: 77%;
    height: auto;
  }
  
  /* Responsive Styles */
  @media (max-width: 1200px) {
    .card3 {
      max-width: 300px; /* Adjusted width for large tablets and small desktops */
    }
  }
  
  @media (max-width: 992px) {
    .card3 {
      max-width: 250px; /* Adjusted width for tablets */
    }
  
  
    .card3 .text {
      font-size: 12px; /* Decrease text font size */
    }
  }
  
  @media (max-width: 768px) {
    .card3 {
      max-width: 200px; /* Adjusted width for small tablets and large phones */
    }
  
   
  
    .card3 .text {
      font-size: 11px; /* Further decrease text font size */
    }
  }
  
  @media (max-width: 576px) {
    .card3 {
      max-width: 150px; /* Adjusted width for small phones */
    }
  
  
  
    .card3 .text {
      font-size: 10px; /* Further decrease text font size */
    }
  }
  