
body {
  margin: 0;
  font-family: 'Rubik', sans-serif; 
}
 * {
  font-family: 'Rubik', sans-serif; 
 }
 .vanta-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}