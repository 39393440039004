.main {
  position: relative;
  height: 100vh;
}
.header-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}
.at{
  font-family: 'Rubik', sans-serif; 
 
}
.ot{
  font-family: 'Rubik', sans-serif; 
  
}
.clientteam{
  margin-top: -100px;
}

.kt{
  font-family: 'Rubik', sans-serif; 
  
}
.video-background {
  position: relative;
  top: 0;
  left: 0;
  height: 600px; /* or whatever height you need */
  width: 100%; /* Ensure the container takes the full width */
  overflow: hidden;
  z-index: 1;
}
.globe{
  width: 100%;
  height: 100%;
  /* position: relative; */
}
.video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.content {
  margin-left: 20%;
  margin-top: 16%;
  width: 55%;
  height: max-content;
  padding: 1rem;
  background-color: rgba(10, 9, 9, 0.4);
}
.kop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  padding: 20px;
}
.kop > div {
  flex: 1;
}
.kop > div:last-child {
  margin-left: 20px;
}
.kop .anivid {
  max-width: 88%;
  height: auto;
}
.serv {
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
  padding: 20px;
}
.wrapperr {
  width: 100%;
  overflow-x: auto;
}
.containercards {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.cards {
  width: 300px;
  margin-right: 20px;
}
@media screen and (max-width: 768px) {
  .cards {
    width: calc(10% - 20px);
    margin-right: 0;
    margin-bottom: 20px;
  }
}
/*  */
.whycode, .services, .client, .team {
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
  padding: 20px;
}
.whycode-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.image-container {
  flex: 1;
  margin-right: 20px;
}
.image-container img {
  width: 100%;
  height: auto;
}
.text-container {
  flex: 2;
}
.container12 {
  display: flex;
  flex-wrap: wrap;
}
.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  z-index: 1000;
}

.whatsapp-icon a {
  color: white;
  text-decoration: none;
}

.whatsapp-icon a:hover {
  color: white;
}

.container12 > div {
  flex: 1 1 calc(33.33% - 20px);
  margin: 10px;
  padding: 20px;
  text-align: center;
}
.slider-container {
  width: 100%;
  padding: 25px;
  margin: 100px auto;
  height: 400px;
  text-align: center;
  border-radius: 30px;
  border: 1px solid #393737;
  box-shadow: 0 0 10px rgba(125, 122, 122, 0.886);
  position: relative;
}
.quote-container {
  font-size: 15px;
  margin-bottom: 20px;
  position: relative;
  margin-top: 74px;
}
.author {
  font-style: italic;
}
.dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #888;
  margin: 0 5px;
  cursor: pointer;
}
.dot.active {
  background-color: #333;
}
.quote-container img {
  position: absolute;
}
.quote-container img:first-child {
  top: -81px;
  left: 0;
}
.quote-container img:last-child {
  bottom: -67px;
  right: 0;
}
.circle-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.circle {
  width: 263px;
  height: 230px;
  top: 60.95px;
  margin-top: 85px;
  border-radius: 150.29px 150.29px 0 0;
  background-color: #5EB8D1;
}
.circle-image {
  margin-top: -51px;
  margin-left: 33px;
  width: 204.86px;
  height: 187.86px;
  left: 37.57px;
  border-radius: 751.43px 751.43px 751.43px 0;
}
.serv {
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
  padding: 20px;
}
#gl2{
  display: none;
}
.wrapperr {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  z-index: 2;
}

.containercards {
  height: 400px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
  overflow-x: auto; /* Allow horizontal scrolling */
}
.cards {
  width: 80px;
  height: 100%;
  border-radius: .75rem;
  background-size: cover;
  cursor: pointer;
  overflow: hidden;
  border-radius: 2rem;
  margin: 0 10px;
  display: flex;
  align-items: flex-end;
  transition: width 0.6s cubic-bezier(.28, -0.03, 0, .99), transform 0.3s ease;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.8);
}
.cards.hovered {
  width: 600px;
}
.cards > .rows {
  color: white;
  display: flex;
  flex-wrap: nowrap;
}
.cards > .rows > .icons {
  background: #223;
  color: white;
  border-radius: 50%;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
}
.cards > .rows > .descriptions {
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  height: 80px;
  width: 520px;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.cards.hovered .descriptions {
  opacity: 1 !important;
  transform: translateY(0) !important;
}
.descriptions p {
  color: black;
  padding-top: 5px;
}
.descriptions h4 {
  text-transform: uppercase;
}

@media (max-width: 600px) {
  .content {
    margin-left: 10%;
    margin-top: 28%;
    width: 80%;
  }
  .content h1 {
    font-size: 30px;
  }
  .content p, .content button {
    font-size: 14px;
    margin-left: 10px;
  }
  .kop, .whycode, .services, .client, .team {
    padding: 2rem;
  }
  .h2.text-white{
    width: 30px;
  }
  .kop > div {
    flex: 0.5 0.5;
}
  .kop .anivid, .whycode img, .services img, .client img, .team img {
    width: 100%;
    height: auto;
  }
  .quote-container img {
    width: 10px;
    height: 20px;
   }
  .container12 > div {
    flex: 1 1 100%;
  }
  .circle-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .circle {
    width: 100%;
    height: auto;
  }
  .circle img {
    width: 100%;
    height: auto;
  }
  .wrapperr {
    flex-direction: column;
  }
  .cards {
    width: 100%;
  }
  input:checked + label {
    width: 100%;
  }
}
@media (max-width: 620px) {
#gl{
  display: none;
}
#gl2{
  display: block;
}
}
@media (max-width: 590px) {
  .content {
    margin-top: 28%;
    width: 88%;
    margin-left: 26px;
  }
  .content h1 {
    font-size: 8px;
    padding: 2rem;
    padding-left: 5rem;
    margin-left: 2px;
    margin-right: 500px;
  }
  .wrapperr {
    width: 100%;
    height: 100vh;
  }
  .containercards {
    height: 300px;
    display: contents
  }
  .slider-container {
    width: 100%;
    padding: 25px;
    margin: 100px auto;
    height: auto;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #393737;
    box-shadow: 0 0 10px rgba(125, 122, 122, 0.886);
    position: relative;
  }
  .quote-container img {
    width: 30px;
    height: 30px;
  }
  .content p, .content button {
    font-size: 11px;
    margin-left: 10px;
  }
  .kop, .client, .team {
    padding: 2rem;
  }
  .whycode, .services{
    padding: 2rem;
  }
  .h2.text-white{
    width: 20px;
  }
  .kop > div {
    flex: 1 1;
}
.why{
  margin-top: 20px;
}
/* #gl{
  width: 50px;
} */
@media (max-width: 700px) {
  #gl {
      height: 300px; 
      
  }
  .globe-container {
      height: 100%; 
  }
}
.client img {
    width: 10px;
    height: 20px;
  }
  .whycode img, .kop .anivid{
    width: 110%;
    height: auto;
    margin-left: -15px;
  }
  .container12 > div {
    flex: 1 1 100%;
  }
  .whycode-content{
    font-size: 11px;
  }
  .kop p{
    font-size: 11px;
    margin-left: -50px;
  }
  .kop h2{
    font-size: 18px;
    margin-left: -50px;
  }
  .circle-container {
    grid-template-columns: repeat(1, 0.5fr);
  }
  .circle {
    width: 100%;
    height: auto;
    margin-left: 40px;
  }
  .circle img {
    width: 100%;
    height: auto;
  }
  .wrapperr {
    flex-direction: column;
  }
  .quote {
    font-size: 12px;
  }
   .author {
    font-size: 10px;
   }
  .cards {
    width: 100%;
  }
  input:checked + label {
    width: 100%;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1024px) {
  .content {
    margin-left: 15%;
    margin-top: 12%;
    width: 80%;
  }
  .content h1 {
    font-size: 30px;
  }
  .content p, .content button {
    font-size: 16px;
    margin-left: 20px;
  }
  .kop, .whycode, .services, .client, .team {
    padding: 3rem;
  }
  .client img{
    font-size: 10px;
    height: 20px;
  }
  .containercards {
    height: 300px;
    display: flex;
    width: 100%;
}
  .wrapperr {
    flex-direction: column;
  }
  .container12 > div {
    flex: 1 1 50%;
  }
  .quote-container img {
    width: 10px;
    height: 20px;
   }
   .quote {
    font-size: 14px;
  }
  .author {
    font-size: 12px;
  }
  .circle-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .circle {
    width: 80%;
    height: auto;
  }
  .circle img {
    width: 100%;
    height: auto;
  }
  .wrapperr {
    flex-direction: row;
  }
  .cards {
    width: 50%;
  }
  input:checked + label {
    width: 50%;
  }
}
/* src/LogoCarousel.css */
.carousel {
  overflow: hidden;
  width: 100%;
}

.carousel-track {
  display: flex;
  animation: scroll 20s linear infinite;
  will-change: transform;
}

.carousel-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 130px;
  height: 130px;
  margin: 0 15px;
  background-color: white;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.carousel-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.carousel-logo-wrapper:hover .carousel-logo {
  transform: scale(1.1);
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .carousel-logo-wrapper {
    width: 100px;
    height: 100px;
    margin: 0 10px;
  }
}

@media (max-width: 768px) {
  .carousel-logo-wrapper {
    width: 80px;
    height: 80px;
    margin: 0 8px;
  }
}

@media (max-width: 480px) {
  .carousel-logo-wrapper {
    width: 60px;
    height: 60px;
    margin: 0 5px;
  }
}



@media screen and (min-width: 600px) {
  .wrapperr {
      display: block;
  }
}

@media screen and (max-width: 599px) {
  .wrapperr {
   
    display: none;
  }
}

@media (min-width:1200px) {
  .content{
    margin-left: 23%;
    margin-top: 12%;
  }
  .wrapperr {
    width: 100%;
    height: 70vh;
  }
  /* .containercards{
    margin-left: 18%;
  } */
  
}

@media (min-width:1500px) {
 
  .containercards{
    margin-left: 10%;
  }
  .why{
    font-size: 24px;
  }
  .ot{
    font-size: 48px;
  }
  .at{
    font-size: 23px;
  }
}
@media screen and (min-width: 1500px ) and (max-width : 1700px) {
  .why{
    font-size: 20px;
  }
  .ot{
    font-size: 40px;
  }
  .at{
    font-size: 20px;
  }
}


@media screen and (min-width: 1200px) and (max-width: 1900px) {
  .client {
     margin-top: -40px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 2000px) and (min-height: 600px) and (max-height: 900px) {
  .client {
     margin-top: -150px !important;
  }
}

