.nav {
  background-color: rgba(171, 159, 159, 0.5) !important;
}

.navlop {
  background-color: white !important;
}

@media (max-width: 768px) {
  .navlop img {
    height: 35px;
    width: 90px;
  }
  .navlop .nav-link {
    padding: 0.5rem 0;
  }
}

@media (min-width: 1600px) {
  .headimg {
    margin-left: -135px;
  }
}

.nav .nav-link {
  color: white !important;
  font-family: 'Montserrat'; 

}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050; /* Higher than navbar to overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  width: 100%;
  height: 100%;
}

.spinner-logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* background-color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.spinner-logo img {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
