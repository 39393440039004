/* General Styles */



.bg-black {
    background-color: #000;
}

.text-white {
    color: #fff;
}

.serviceimage {
    width: 100%;
    height: auto;
    font-family: 'Rubik', sans-serif; 
    font-weight: 400;
    font-style: normal;
}

.servicehead {
    font-family: 'Rubik', sans-serif; 
    font-size: 64px;
    font-weight: 600;
    line-height: 75.84px;
    color: rgba(255, 255, 255, 1);
    margin-top: -27%;
    margin-left: 71%;
}

.parag {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 18%;
    background-color: black;
    padding: 20px;
}

.parag p {
    max-width: 800px;
    text-align: center;
}

.full {
    background-color: black;
    padding: 20px;
}

.full h1 {
    text-align: center;
    margin-top: -100px;
}

@keyframes outlineMove {
    0% {
        clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%);
        border-color: rgba(241, 145, 161, 0.514);
    }
    25% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
        border-color: rgba(233, 130, 147, 0.422);
    }
    50% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%);
        border-color: rgba(255, 192, 203, 0.45);
    }
    75% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        border-color: rgba(233, 130, 147, 0.468);
    }
    100% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 0%);
        border-color: rgba(255, 192, 203, 0.454);
    }
}

.development-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.development {
    position: relative; /* Added to position the pseudo-element */
    text-align: center;
    flex: 1;
    min-width: 200px;
    max-width: 300px;
    margin: 10px;
    padding: 20px;
    background-color: #222;
    border-radius: 10px;
    transition: transform 0.3s;
    z-index: 2;
}

.development::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: 5px solid transparent;
    border-radius: 10px;
    clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%);
    transition: border-color 0.3s;
}

.development:hover::before {
    animation: outlineMove 2s linear infinite;
}

.development img {
    display: block;
    margin: 0 auto;
}

.development:hover {
    transform: scale(1.05);
}

.prod {
    background-color: black;
    padding: 20px;
}

.prod h1 {
    font-size: 35px;
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    
    justify-content: space-between;
    padding: 60px;
}

.card {
    flex: 0 0 calc(45% - 10px); /* Adjust width to fit two cards in one row */
    margin-bottom: 20px; /* Space between rows */
    position: relative;
    overflow: hidden;
    z-index: 2;
}

.card img {
    width: 100%;
    height: auto;
}

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 73%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 20px;
    box-sizing: border-box;
    text-align: left;
    overflow: hidden;
    transition: transform 0.3s ease, opacity 0.3s ease;
}


/* Hover effect for larger screens */
@media (min-width: 768px) {
    .card:hover .overlay {
        transform: scale(1.05);
        opacity: 0.9;
    }

    .overlay h1 {
        font-size: 20px;
        transition: font-size 0.3s ease;
    }

    .overlay p {
        font-size: 14px;
        transition: font-size 0.3s ease;
    }

    .card:hover .overlay h1 {
        font-size: 24px;
    }

    .card:hover .overlay p {
        font-size: 16px;
    }
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .servicehead {
        font-size: 48px;
        line-height: 56px;
        margin-top: -20%;
        margin-left: 60%;
    }

    .parag {
        margin-top: 15%;
    }

    .development-row {
        justify-content: center;
    }

    .card1 {
        width: calc(100% - 20px);
    }
}

@media (max-width: 768px) {
    .servicehead {
        font-size: 36px;
        line-height: 42px;
        margin-top: -15%;
        margin-left: 40%;
    }

    .parag {
        margin-top: 10%;
        padding: 10px;
    }

    .full h1 {
        font-size: 24px;
    }

    .development-row {
        flex-direction: column;
    }

    .development {
        width: 315px;
        margin-left: -50px;
    }

    .cards-container {
        flex-direction: column;
    }
    .card1 {
        width: calc(100% - 20px);
        margin: 10px auto;
    }
    .overlay {
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .servicehead {
        font-size: 24px;
        line-height: 28px;
        margin-top: -10%;
        margin-left: 20%;
    }
    .parag {
        margin-top: 5%;
    }
    .full h1 {
        font-size: 18px;
    }
 
}


@media (min-width: 1200px) {
    .development-row {
        flex-direction: row;      
    }  
}

.development p {
    font-size: 14px;
}

@media (min-width: 1500px) {
    .development-row {
        flex-direction: row;
        
    }
    .development{
        max-width: 395px;
    }
    .parag p {
        margin-top: 34px;
    }
    .development p {
        font-size: 20px;
    }
    .overlay p {
        font-size: 20px;
    }
    .overlay h1{
        font-size: 35px;
    }
    .parag p{
        font-size: 17px;
    }
}
@media (max-width: 370px) {
    .moil{
        margin-left: -4px;
    }    
}
@media (min-width: 390px) {
    .moil{
        margin-left: 34px;
    }  
 
}
@media screen and (min-width: 350px ) and (max-width : 600px) {
  
    .overlay h1{
        font-size: 11px;
    }
    .overlay p{
        font-size: 8px;
    }
    .cards-container{
        padding: 30px;
    }
}
@media screen and (min-width: 764px ) and (max-width : 1000px) {
  
    .overlay h1{
        font-size: 16px;
    }
    .overlay p{
        font-size: 11px;
    }
}

@media screen and (min-width: 390px ) and (max-width : 400px) {
    .development{
        margin-left: -69px;
    }
}
